import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

export class SessionSecurity {
  static SESSION_KEY = 'session_token';
  static DEVICE_ID_KEY = 'device_id';

  static generateToken() {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  static generateDeviceId() {
    const nav = window.navigator;
    const screen = window.screen;
    const deviceData = [
      nav.userAgent,
      screen.height,
      screen.width,
      nav.language,
      nav.platform
    ].join('|');
    
    return btoa(deviceData);
  }

  static initSession() {
    const sessionToken = this.generateToken();
    const deviceId = this.generateDeviceId();
    
    localStorage.setItem(this.SESSION_KEY, sessionToken);
    localStorage.setItem(this.DEVICE_ID_KEY, deviceId);
    
    return { sessionToken, deviceId };
  }

  static validateSession() {
    const currentDeviceId = this.generateDeviceId();
    const storedDeviceId = localStorage.getItem(this.DEVICE_ID_KEY);
    const sessionToken = localStorage.getItem(this.SESSION_KEY); // Bu satırı düzelttik

    if (!sessionToken || !storedDeviceId || currentDeviceId !== storedDeviceId) {
      this.terminateSession();
      return false;
    }
    return true;
  }

  static async terminateSession() {
    try {
      await signOut(auth);
      localStorage.removeItem(this.SESSION_KEY);
      localStorage.removeItem(this.DEVICE_ID_KEY);
      window.location.href = '/';
    } catch (error) {
      console.error('Session termination error:', error);
    }
  }

  static startSessionMonitoring() {
    // Her 5 dakikada bir session kontrolü
    setInterval(() => {
      if (!this.validateSession()) {
        console.warn('Invalid session detected');
      }
    }, 5 * 60 * 1000);

    // Sekme/pencere değişikliklerini izle
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.validateSession();
      }
    });

    // Network değişikliklerini izle
    window.addEventListener('online', () => this.validateSession());
  }
}