import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Home, Users, Briefcase, FileText, PieChart, LogOut } from 'lucide-react';

const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const menuItems = [
    { 
      path: '/home', 
      name: 'Ana Sayfa',
      icon: Home,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/customers', 
      name: 'Müşteriler',
      icon: Users,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/employee', 
      name: 'Çalışanlar',
      icon: Briefcase,
      allowedRoles: ['admin']
    },
    { 
      path: '/services', 
      name: 'İşlemler',
      icon: FileText,
      allowedRoles: ['admin', 'cashier']
    },
    { 
      path: '/report', 
      name: 'Raporlar',
      icon: PieChart,
      allowedRoles: ['admin']
    }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Çıkış yapılırken hata oluştu:', error);
    }
  };

  return (
    <div className="bg-gradient-to-r from-cyan-500 to-blue-500 pb-1">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo Area */}
            <div className="flex-shrink-0 flex items-center">
              <span className="text-xl font-bold bg-gradient-to-r from-cyan-600 to-blue-600 bg-clip-text text-transparent">
                Adisyon Takip
              </span>
            </div>

            {/* Navigation Items */}
            <div className="hidden sm:flex sm:space-x-4">
              {menuItems.map((item) => {
                if (!item.allowedRoles.includes(user?.role)) {
                  return null;
                }

                return (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    className={({ isActive }) =>
                      `px-3 py-2 text-sm font-medium rounded-md transition-all duration-300
                      flex items-center space-x-1
                      ${isActive 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-blue-600 hover:bg-blue-50'
                      }`
                    }
                  >
                    <item.icon className="h-4 w-4" />
                    <span>{item.name}</span>
                  </NavLink>
                );
              })}
            </div>

            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-600 
                       hover:text-red-600 hover:bg-red-50 rounded-md transition-all duration-300"
            >
              <LogOut className="h-4 w-4 mr-1" />
              <span>Çıkış Yap</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;