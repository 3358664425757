import React from 'react';
import { Calendar, User, Briefcase, FileText, CreditCard, Tag, Trash2, Edit2 } from 'lucide-react';
import CustomDeleteDialog from './CustomDeleteDialog';

const BillCard = ({ bill, onEdit, onDelete }) => {
  const getDateColor = (dateStr) => {
    const isToday = new Date(dateStr).toDateString() === new Date().toDateString();
    return isToday 
      ? 'bg-gradient-to-r from-green-50 to-green-100' 
      : 'bg-gradient-to-r from-orange-50 to-orange-100';
  };

  const getDiscountedPriceStyle = (hasDiscount) => {
    return hasDiscount ? 'line-through text-gray-400' : 'text-gray-900';
  };

  return (
    <div className="w-full max-w-sm bg-white rounded-lg shadow transition-all duration-300 hover:shadow-lg">
      <div className="p-6">
        {/* Tarih Başlığı */}
        <div className={`${getDateColor(bill.date)} -mx-6 -mt-6 px-6 py-3 mb-4 rounded-t-lg flex items-center gap-2`}>
          <Calendar className="h-4 w-4 text-gray-600" />
          <span className="font-medium text-gray-700">
            {new Date(bill.date).toLocaleDateString("tr-TR")}
          </span>
        </div>

        {/* Müşteri ve Çalışan Bilgileri */}
        <div className="space-y-3 mb-4">
          <div className="flex items-center gap-2">
            <User className="h-4 w-4 text-blue-500" />
            <span className="text-gray-600">
              <span className="font-medium text-gray-900">Müşteri:</span> {bill.customerName}
            </span>
          </div>
          
          <div className="flex items-center gap-2">
            <Briefcase className="h-4 w-4 text-blue-500" />
            <span className="text-gray-600">
              <span className="font-medium text-gray-900">Çalışan:</span> {bill.employeeName}
            </span>
          </div>

<div className="flex gap-2">
  <div className="flex-shrink-0 mt-1">
    <FileText className="h-4 w-4 text-blue-500" />
  </div>
  <div className="text-gray-600">
    <span className="font-medium text-gray-900">İşlemler:</span> 
    {/* Servisleri seans bilgisiyle birlikte listele */}
    {bill.services.map((service, index) => (
      <span key={`${service.id}-${index}`}>
        {service.name}
        {service.sessions > 1 ? ` (${service.sessions} Seans)` : ''}
        {index < bill.services.length - 1 ? ', ' : ''}
      </span>
    ))}
  </div>
</div>
        </div>

        {/* Fiyat Bilgileri */}
        <div className="bg-gray-50 rounded-lg p-3 space-y-2 mb-4">
          <div className="flex items-center justify-between">
            <span className="flex items-center gap-2">
              <Tag className="h-4 w-4 text-blue-500" />
              <span className="font-medium text-gray-900">Fiyat:</span>
            </span>
            <div>
              <span className={getDiscountedPriceStyle(bill.discount > 0)}>
                {bill.price} TL
              </span>
              {bill.discount > 0 && (
                <span className="ml-2 text-green-600 font-medium">
                  {bill.totalPrice} TL
                </span>
              )}
            </div>
          </div>

          {bill.discount > 0 && (
            <div className="flex items-center justify-between text-red-500">
              <span className="font-medium">İndirim:</span>
              <span>{bill.discount} TL</span>
            </div>
          )}

          <div className="flex items-center justify-between">
            <span className="flex items-center gap-2">
              <CreditCard className="h-4 w-4 text-blue-500" />
              <span className="font-medium text-gray-900">Ödeme:</span>
            </span>
            <span className="text-gray-600">{bill.paymentType}</span>
          </div>
        </div>

        {/* Aksiyon Butonları */}
        <div className="flex gap-2 justify-end">
          <button
            onClick={() => onEdit(bill)}
            className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors duration-300"
          >
            <Edit2 className="h-4 w-4" />
            <span>Düzenle</span>
          </button>
  	 <CustomDeleteDialog onConfirm={() => onDelete(bill.id)} />
        </div>
      </div>
    </div>
  );
};

export default BillCard;