import React from 'react';
import { X, Plus, FileText, AlertCircle } from 'lucide-react';
import SearchableDropdown from './SearchableDropdown';

const MultiServiceSelector = ({ 
  services,
  selectedServices,
  setSelectedServices,
  maxServices = 10,
  maxSessionsPerService = 10 
}) => {
  // Seçili servisleri grupla
  const groupedServices = selectedServices.reduce((acc, service) => {
    const existingGroup = acc.find(group => group.id === service.id);
    if (existingGroup) {
      existingGroup.count++;
      existingGroup.totalPrice = existingGroup.count * service.price;
    } else {
      acc.push({
        ...service,
        count: 1,
        totalPrice: service.price
      });
    }
    return acc;
  }, []);

  const handleServiceSelect = (service) => {
    const existingService = groupedServices.find(s => s.id === service.id);
    const uniqueServicesCount = groupedServices.length;
    
    if (existingService) {
      if (existingService.count >= maxSessionsPerService) {
        alert(`Bir işlem için en fazla ${maxSessionsPerService} seans eklenebilir`);
        return;
      }
      // Aynı servisten bir tane daha ekle
      setSelectedServices([...selectedServices, service]);
    } else if (uniqueServicesCount >= maxServices) {
      alert(`En fazla ${maxServices} farklı işlem eklenebilir`);
      return;
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const handleServiceRemove = (serviceId) => {
    const serviceIndex = selectedServices.map(s => s.id).lastIndexOf(serviceId);
    if (serviceIndex !== -1) {
      const newServices = [...selectedServices];
      newServices.splice(serviceIndex, 1);
      setSelectedServices(newServices);
    }
  };

  const handleServiceAdd = (serviceId) => {
    const service = services.find(s => s.id === serviceId);
    const existingService = groupedServices.find(s => s.id === serviceId);
    
    if (existingService && existingService.count >= maxSessionsPerService) {
      alert(`Bir işlem için en fazla ${maxSessionsPerService} seans eklenebilir`);
      return;
    }
    
    if (service) {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const calculateTotal = () => {
    return selectedServices.reduce((sum, service) => sum + Number(service.price), 0);
  };

  return (
    <div className="space-y-4">
      <SearchableDropdown
        items={services}
        onSelect={handleServiceSelect}
        placeholder="İşlem veya Ürün Ara..."
        icon={FileText}
        resetAfterSelect={true}
      />
      
      {groupedServices.length > 0 && (
        <div className="space-y-2">
          <div className="flex flex-wrap gap-2">
            {groupedServices.map(service => (
              <div
                key={service.id}
                className="flex items-center gap-2 bg-blue-50 text-blue-700 px-3 py-1.5 rounded-full text-sm"
              >
                <button
                  onClick={() => handleServiceAdd(service.id)}
                  className="hover:text-blue-800 transition-colors p-0.5 hover:bg-blue-100 rounded-full"
                >
                  <Plus className="h-3 w-3" />
                </button>
                <span>
                  {service.name}
                  {service.count > 1 && (
                    <span className="ml-1 font-medium">
                      ({service.count} Seans)
                    </span>
                  )}
                </span>
                <span className="text-blue-500">
                  ({service.totalPrice} TL)
                </span>
                <button
                  onClick={() => handleServiceRemove(service.id)}
                  className="hover:text-red-600 transition-colors p-0.5 hover:bg-blue-100 rounded-full"
                >
                  <X className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
          
          <div className="flex flex-col gap-1 pt-2 text-sm">
            <div className="flex items-center gap-1 text-gray-600">
              <AlertCircle className="h-4 w-4" />
              <span>
                En fazla {maxServices} farklı işlem ve her işlem için {maxSessionsPerService} seans eklenebilir
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">
                {groupedServices.length} işlem seçildi
              </span>
              <div className="font-medium">
                Toplam: {calculateTotal()} TL
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiServiceSelector;