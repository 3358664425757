import React, { useState } from 'react';
import { Trash2, X } from 'lucide-react';

const CustomDeleteDialog = ({ onConfirm, entityType = "adisyon" }) => {
  const [showModal, setShowModal] = useState(false);

  const getEntityText = () => {
    switch (entityType) {
      case 'musteri':
        return 'müşteriyi';
      case 'calisan':
        return 'çalışanı';
      case 'islem':
        return 'işlemi';
      default:
        return 'adisyonu';
    }
  };

  const getModalTitle = () => {
    switch (entityType) {
      case 'musteri':
        return 'Müşteri Silme Onayı';
      case 'calisan':
        return 'Çalışan Silme Onayı';
      case 'islem':
        return 'İşlem Silme Onayı';
      default:
        return 'Adisyon Silme Onayı';
    }
  };

  const handleConfirm = () => {
    onConfirm();
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="inline-flex items-center px-3 py-1 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors"
      >
        <Trash2 className="h-4 w-4 mr-1" />
        <span>Sil</span>
      </button>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div 
            className="absolute inset-0 bg-black/30"
            onClick={() => setShowModal(false)}
          />
          
          <div className="relative bg-white rounded-lg shadow-xl w-full max-w-md mx-4 p-6">
            <div className="absolute right-4 top-4">
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="mt-2">
              <h3 className="text-lg font-semibold text-gray-900 text-left">
                {getModalTitle()}
              </h3>
              <p className="mt-2 text-sm text-gray-500 break-words">
                Bu {getEntityText()} silmek istediğinize emin misiniz? Bu işlem geri alınamaz.
              </p>
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
              >
                İptal
              </button>
              <button
                onClick={handleConfirm}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors"
              >
                Sil
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomDeleteDialog;