// Şifre gücünü kontrol eden utility fonksiyonlar
export const passwordStrengthChecks = {
  minLength: (password) => password.length >= 8,
  hasUpperCase: (password) => /[A-Z]/.test(password),
  hasLowerCase: (password) => /[a-z]/.test(password),
  hasNumbers: (password) => /\d/.test(password),
  hasSpecialChar: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  noConsecutiveChars: (password) => !/(.)\1{2,}/.test(password),
  noCommonPasswords: (password) => {
    const commonPasswords = ['password', '123456', 'qwerty', 'admin'];
    return !commonPasswords.includes(password.toLowerCase());
  }
};

export const validatePassword = (password) => {
  const checks = {
    length: {
      valid: passwordStrengthChecks.minLength(password),
      message: 'Şifre en az 8 karakter olmalıdır'
    },
    upperCase: {
      valid: passwordStrengthChecks.hasUpperCase(password),
      message: 'En az bir büyük harf içermelidir'
    },
    lowerCase: {
      valid: passwordStrengthChecks.hasLowerCase(password),
      message: 'En az bir küçük harf içermelidir'
    },
    number: {
      valid: passwordStrengthChecks.hasNumbers(password),
      message: 'En az bir rakam içermelidir'
    },
    specialChar: {
      valid: passwordStrengthChecks.hasSpecialChar(password),
      message: 'En az bir özel karakter içermelidir (!@#$%^&*)'
    },
    noRepeating: {
      valid: passwordStrengthChecks.noConsecutiveChars(password),
      message: 'Ardışık tekrar eden karakterler içeremez'
    },
    notCommon: {
      valid: passwordStrengthChecks.noCommonPasswords(password),
      message: 'Çok yaygın bir şifre kullanılamaz'
    }
  };

  const errors = Object.entries(checks)
    .filter(([_, check]) => !check.valid)
    .map(([_, check]) => check.message);

  return {
    isValid: errors.length === 0,
    errors,
    strength: calculatePasswordStrength(checks)
  };
};

const calculatePasswordStrength = (checks) => {
  const validChecks = Object.values(checks).filter(check => check.valid).length;
  const totalChecks = Object.keys(checks).length;
  return (validChecks / totalChecks) * 100;
};