import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, PlusCircle, Edit2, Trash2, FileText, Tag, X, Save, DollarSign, Package } from 'lucide-react';
import CustomDeleteDialog from '../components/CustomDeleteDialog';

Modal.setAppElement("#root");

function Services() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [serviceForm, setServiceForm] = useState({
    name: "",
    price: "",
    type: "İşlem",
    status: "active",
  });

  // Veritabanından aktif işlemleri çekme
const fetchServices = async () => {
  try {
    const q = query(
      collection(db, "services"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const servicesData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Alfabetik sıralama
    const sortedServices = servicesData.sort((a, b) => {
      return a.name.localeCompare(b.name, 'tr', { sensitivity: 'base' });
    });
    
    setServices(sortedServices);
    setFilteredServices(sortedServices);
  } catch (error) {
    console.error("Veri çekme hatası:", error);
  }
};

  useEffect(() => {
    fetchServices();
  }, []);

  // Arama çubuğu
const handleSearch = (e) => {
  const inputValue = e.target.value;
  const searchValue = inputValue.toLowerCase();
  setSearchTerm(inputValue); // Orijinal değeri kullan
  const filtered = services.filter((service) =>
    service.name.toLowerCase().includes(searchValue)
  );
  setFilteredServices(filtered);
};

const groupedServices = filteredServices.reduce((acc, service) => {
  const type = service.type || "İşlem"; // Eski kayıtlar için varsayılan değer
  if (!acc[type]) {
    acc[type] = [];
  }
  acc[type].push(service);
  return acc;
}, {});

  // Modal açma/kapatma
  const openModal = (service = null) => {
    setModalIsOpen(true);
    if (service) {
      setIsEditing(true);
      setSelectedServiceId(service.id);
      setServiceForm({ name: service.name, price: service.price, type: service.type || "İşlem" });
    } else {
      setIsEditing(false);
      setSelectedServiceId(null);
      setServiceForm({ name: "", price: "", type: "İşlem", status: "active" });
    }
  };

  const closeModal = () => setModalIsOpen(false);

const capitalizeFirstLetter = (str) => {
  if (!str) return str;

  const turkishLetterMap = {
    'i': 'İ',
    'ı': 'I',
  };

  const words = str.trim().replace(/\(([^)]+)\)/g, ' ($1) ')  // Parantezlerin etrafına boşluk ekle
    .split(/\s+/)  // Boşluklara göre böl
    .filter(word => word.length > 0);  // Boş stringleri filtrele

  return words.map(word => {
    if (word.startsWith('(') && word.endsWith(')')) {
      // Parantez içindeki kelimeyi işle
      const innerWord = word.slice(1, -1);
      const capitalizedInner = innerWord.charAt(0).toUpperCase() + innerWord.slice(1).toLowerCase();
      return `(${capitalizedInner})`;
    } else {
      // Normal kelimeyi işle
      const firstLetter = word.charAt(0);
      const restOfWord = word.slice(1).toLowerCase();
      const capitalizedFirst = turkishLetterMap[firstLetter.toLowerCase()] || firstLetter.toUpperCase();
      return capitalizedFirst + restOfWord;
    }
  }).join(' ');
};

  // İşlem kaydetme (benzersiz isim kontrolü)
const handleSaveService = async () => {
  try {
    // İsmi formatla
    const formattedName = capitalizeFirstLetter(serviceForm.name);

    const servicesRef = collection(db, "services");
    const duplicateQuery = query(
      servicesRef,
      where("name", "==", formattedName), // Formatlı isimle kontrol
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(duplicateQuery);

    const isDuplicate = querySnapshot.docs.some(
      (doc) => doc.id !== selectedServiceId
    );

    if (isDuplicate) {
      alert("Bu isimde bir işlem zaten var!");
      return;
    }

    // Formatlı veriyi kaydet
    const formattedServiceForm = {
      ...serviceForm,
      name: formattedName,
    };

    if (isEditing) {
      const serviceRef = doc(db, "services", selectedServiceId);
      await updateDoc(serviceRef, formattedServiceForm);
      alert("İşlem güncellendi!");
    } else {
      await addDoc(servicesRef, formattedServiceForm);
      alert("Yeni işlem eklendi!");
    }

    fetchServices();
    closeModal();
  } catch (error) {
    console.error("İşlem kaydedilirken hata:", error);
  }
};

  // Soft delete
  const handleDeleteService = async (serviceId) => {
    try {
      const serviceRef = doc(db, "services", serviceId);
      await updateDoc(serviceRef, { status: "deleted" });
      alert("İşlem silindi (soft delete).");
      fetchServices();
    } catch (error) {
      console.error("Silme hatası:", error);
    }
  };

return (
  <div className="p-6">
    {/* Header */}
    <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
      {/* Arama Alanı */}
      <div className="relative w-full md:w-96">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
        <input
          type="text"
          placeholder="İşlem veya Ürün Ara"
          value={searchTerm}
          onChange={handleSearch}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      {/* Yeni Ekle Butonu */}
      <button
        onClick={() => openModal()}
        className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
      >
        <PlusCircle className="h-5 w-5" />
        Yeni Ekle
      </button>
    </div>

    {/* İşlemler Bölümü */}
    <div className="mb-8">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center gap-2">
        <FileText className="h-5 w-5 text-blue-500" />
        İşlemler
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredServices
          .filter(service => service.type === "İşlem" || !service.type)
          .map((service) => (
            <div
              key={service.id}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="p-6">
                {/* İşlem İsmi */}
                <div className="flex items-start mb-4">
                  <FileText className="h-5 w-5 text-blue-500 mt-1 mr-2 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium text-gray-900">{service.name}</h3>
                  </div>
                </div>

                {/* Fiyat */}
                <div className="flex items-center mb-6">
                  <Tag className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-lg font-semibold text-green-600">
                    {service.price} TL
                  </span>
                </div>

                {/* Aksiyon Butonları */}
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => openModal(service)}
                    className="inline-flex items-center px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                  >
                    <Edit2 className="h-4 w-4 mr-1" />
                    Düzenle
                  </button>
                  <CustomDeleteDialog onConfirm={() => handleDeleteService(service.id)} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>

    {/* Ürünler Bölümü */}
    <div>
      <h2 className="text-xl font-semibold mb-4 text-gray-800 flex items-center gap-2">
        <Package className="h-5 w-5 text-pink-500" />
        Ürünler
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredServices
          .filter(service => service.type === "Ürün")
          .map((service) => (
            <div
              key={service.id}
              className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="p-6">
                {/* Ürün İsmi */}
                <div className="flex items-start mb-4">
                  <Package className="h-5 w-5 text-pink-500 mt-1 mr-2 flex-shrink-0" />
                  <div>
                    <h3 className="font-medium text-gray-900">{service.name}</h3>
                  </div>
                </div>

                {/* Fiyat */}
                <div className="flex items-center mb-6">
                  <Tag className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-lg font-semibold text-green-600">
                    {service.price} TL
                  </span>
                </div>

                {/* Aksiyon Butonları */}
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => openModal(service)}
                    className="inline-flex items-center px-3 py-1.5 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                  >
                    <Edit2 className="h-4 w-4 mr-1" />
                    Düzenle
                  </button>
                  <CustomDeleteDialog onConfirm={() => handleDeleteService(service.id)} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>

    {/* Modal */}
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        },
        content: {
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '500px',
          width: '90%',
          margin: '0 auto',
          padding: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'visible'
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white flex items-center gap-2">
            <FileText className="h-5 w-5" />
            {isEditing ? "Düzenle" : "Yeni Ekle"}
          </h2>
          <button
            onClick={closeModal}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 space-y-4">
          {/* İsim */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-blue-500" />
              İsim
            </label>
            <input
              type="text"
              placeholder="İsim"
              value={serviceForm.name}
              onChange={(e) => setServiceForm({ ...serviceForm, name: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          {/* Hizmet Tipi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Tag className="h-4 w-4 text-blue-500" />
              Hizmet Tipi
            </label>
            <select
              value={serviceForm.type}
              onChange={(e) => setServiceForm({ ...serviceForm, type: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="İşlem">İşlem</option>
              <option value="Ürün">Ürün</option>
            </select>
          </div>

          {/* Fiyat */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <DollarSign className="h-4 w-4 text-blue-500" />
              Fiyat
            </label>
            <input
              type="number"
              placeholder="Fiyat"
              value={serviceForm.price}
              onChange={(e) => setServiceForm({ ...serviceForm, price: e.target.value })}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>

        {/* Modal Footer */}
        <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3">
          <button
            onClick={closeModal}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
          >
            İptal
          </button>
          <button
            onClick={handleSaveService}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
          >
            <Save className="h-4 w-4" />
            {isEditing ? "Güncelle" : "Kaydet"}
          </button>
        </div>
      </div>
    </Modal>
  </div>
);
}

export default Services;
