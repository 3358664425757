// ProtectedRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, loading, authInitialized } = useAuth();
  const location = useLocation();

  // Auth başlatılmamışsa hiçbir şey gösterme
  if (!authInitialized) {
    return null;
  }

  // Yükleme durumunda loading komponenti göster
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div>Yükleniyor...</div>
      </div>
    );
  }

  // Kullanıcı oturum açmamışsa login sayfasına yönlendir
  if (!user) {
    if (process.env.NODE_ENV === 'development') {
      console.log("Auth durumu:", { user, loading, authInitialized });
    }
    
    return <Navigate to="/" state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default ProtectedRoute;