import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

export class AuditLogger {
  static async log(userId, action, resourceType, resourceId, details) {
    try {
      await addDoc(collection(db, 'audit_logs'), {
        userId,
        action, // 'create', 'update', 'delete', 'read'
        resourceType, // 'bill', 'user', etc.
        resourceId,
        details,
        timestamp: serverTimestamp(),
        userAgent: navigator.userAgent,
        ipAddress: await this.getIpAddress(),
      });
    } catch (error) {
      console.error('Audit log error:', error);
    }
  }

  static async getIpAddress() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch {
      return 'unknown';
    }
  }
}