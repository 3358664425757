import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  query,
  where,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, UserPlus, Edit2, Trash2, User, Mail, Phone, X, Save, Briefcase } from 'lucide-react';
import PhoneInput from '../components/PhoneInput';
import { validateCustomer } from '../utils/validationUtils';
import CustomDeleteDialog from '../components/CustomDeleteDialog';

Modal.setAppElement("#root");

function Employee() {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [employeeForm, setEmployeeForm] = useState({
    name: "",
    surname: "",
    emailAddress: "",
    phone: {
      countryCode: '+90',
      number: ''
    },
    role: "employee",
    status: "active",
  });

  // Çalışanları Firestore'dan çek
const fetchEmployees = async () => {
  try {
    const q = query(
      collection(db, "users"),
      where("role", "==", "employee"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const employeesData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const phoneData = typeof data.phone === 'string' 
        ? { countryCode: '+90', number: data.phone }
        : data.phone || { countryCode: '+90', number: '' };
      
      return {
        id: doc.id,
        ...data,
        phone: phoneData
      };
    });
    
    // Alfabetik sıralama
    const sortedEmployees = employeesData.sort((a, b) => {
      return a.name.localeCompare(b.name, 'tr', { sensitivity: 'base' });
    });
    
    setEmployees(sortedEmployees);
    setFilteredEmployees(sortedEmployees);
  } catch (error) {
    console.error("Veri çekme hatası:", error);
  }
};

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Arama çubuğu
const handleSearch = (e) => {
  const inputValue = e.target.value;
  const searchValue = inputValue.toLowerCase();
  setSearchTerm(inputValue); // Orijinal değeri kullan
  const filtered = employees.filter(
    (employee) =>
      employee.name.toLowerCase().includes(searchValue) ||
      employee.emailAddress.toLowerCase().includes(searchValue) ||
      (employee.phone?.number || '').toLowerCase().includes(searchValue) ||
      (employee.phone?.countryCode || '').toLowerCase().includes(searchValue)
  );
  setFilteredEmployees(filtered);
};

  // Modal açma/kapatma
const openModal = (employee = null) => {
  setModalIsOpen(true);
  if (employee) {
    setIsEditing(true);
    setSelectedEmployeeId(employee.id);
    // Eğer eski kayıtlarda phone objesi yoksa, yeni yapıya uygun hale getir
    const phoneData = employee.phone || {
      countryCode: '+90',
      number: employee.phone || ''
    };
    setEmployeeForm({
      ...employee,
      phone: phoneData
    });
  } else {
    setIsEditing(false);
    setSelectedEmployeeId(null);
    setEmployeeForm({
      name: "",
      surname: "",
      emailAddress: "",
      phone: {
        countryCode: '+90',
        number: ''
      },
      role: "employee",
      status: "active",
    });
  }
};

  const closeModal = () => setModalIsOpen(false);

  // Çalışan ekleme/düzenleme
const handleSaveEmployee = async () => {
  try {
    const validation = await validateCustomer(employeeForm, db, selectedEmployeeId);
    
    if (!validation.isValid) {
      const errorMessage = Object.values(validation.errors).join('\n');
      alert(errorMessage);
      return;
    }

    if (isEditing) {
      const employeeRef = doc(db, "users", selectedEmployeeId);
      await updateDoc(employeeRef, employeeForm);
      alert("Çalışan başarıyla güncellendi!");
    } else {
      await addDoc(collection(db, "users"), employeeForm);
      alert("Yeni çalışan başarıyla eklendi!");
    }

    fetchEmployees();
    closeModal();
  } catch (error) {
    console.error("Çalışan kaydedilirken hata:", error);
    alert("Bir hata oluştu: " + error.message);
  }
};

  // Soft delete (Silme)
  const handleDeleteEmployee = async (employeeId) => {
    try {
      const employeeRef = doc(db, "users", employeeId);
      await updateDoc(employeeRef, { status: "deleted" });
      alert("Çalışan silindi (soft delete).");
      fetchEmployees();
    } catch (error) {
      console.error("Silme hatası:", error);
    }
  };

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        {/* Arama Alanı */}
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Çalışan Ara (isim veya e-posta)"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Yeni Çalışan Butonu */}
        <button
          onClick={() => openModal()}
          className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
        >
          <UserPlus className="h-5 w-5" />
          Yeni Çalışan
        </button>
      </div>

      {/* Çalışan Tablosu */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Soyad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefon</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredEmployees.map((employee) => (
                <tr key={employee.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">{employee.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.surname}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{employee.emailAddress}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
  		    {employee.phone?.countryCode} {employee.phone?.number}
		  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">
                    <button
                      onClick={() => openModal(employee)}
                      className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors mr-2"
                    >
                      <Edit2 className="h-4 w-4 mr-1" />
                      Düzenle
                    </button>
<CustomDeleteDialog 
  onConfirm={() => handleDeleteEmployee(employee.id)}
  entityType="calisan"
/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Çalışan Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          },
          content: {
            position: 'relative',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
            maxWidth: '500px',
            width: '90%',
            margin: '0 auto',
            padding: 0,
            border: 'none',
            background: 'transparent',
            overflow: 'visible'
          }
        }}
      >
        <div className="bg-white rounded-lg shadow-xl overflow-visible">
          {/* Modal Header */}
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-white flex items-center gap-2">
              <Briefcase className="h-5 w-5" />
              {isEditing ? "Çalışanı Düzenle" : "Yeni Çalışan"}
            </h2>
            <button
              onClick={closeModal}
              className="text-white hover:text-gray-200 transition-colors"
            >
              <X className="h-6 w-6" />
            </button>
          </div>

          {/* Modal Body */}
          <div className="p-6 space-y-4">
            {/* Ad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Ad
              </label>
              <input
                type="text"
                placeholder="Ad"
                value={employeeForm.name}
                onChange={(e) => setEmployeeForm({ ...employeeForm, name: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Soyad */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <User className="h-4 w-4 text-blue-500" />
                Soyad
              </label>
              <input
                type="text"
                placeholder="Soyad"
                value={employeeForm.surname}
                onChange={(e) => setEmployeeForm({ ...employeeForm, surname: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            {/* Email */}
            <div className="space-y-2">
              <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                <Mail className="h-4 w-4 text-blue-500" />
                Email
              </label>
              <input
                type="email"
                placeholder="Email"
                value={employeeForm.emailAddress}
                onChange={(e) => setEmployeeForm({ ...employeeForm, emailAddress: e.target.value })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

	    {/* Telefon */}
	    <div className="space-y-2">
 	     <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
  	      <Phone className="h-4 w-4 text-blue-500" />
  	      Telefon
 	     </label>
 	     <PhoneInput
  	      value={employeeForm.phone}
  	      onChange={(phoneData) => 
   	       setEmployeeForm(prev => ({
    	        ...prev,
     	       phone: phoneData
     	     }))
    	    }
  	    />
	    </div>
          </div>

          {/* Modal Footer */}
          <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3">
            <button
              onClick={closeModal}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
            >
              İptal
            </button>
            <button
              onClick={handleSaveEmployee}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
            >
              <Save className="h-4 w-4" />
              {isEditing ? "Güncelle" : "Kaydet"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Employee;
