import {
  collection,
  getDocs,
  query,
  where
} from "firebase/firestore";

export const validateCustomer = async (customerData, db, currentCustomerId = null) => {
  const errors = {};
  
  // İsim-soyisim kontrolü
  if (!customerData.name || !customerData.surname) {
    errors.name = 'Ad ve soyad zorunludur';
  }

  // Telefon kontrolü
if (!customerData.phone) {
    errors.phone = 'Telefon numarası zorunludur';
} else {
    const phoneDigits = customerData.phone.number.replace(/\D/g, '');
    const selectedCountryCode = customerData.phone.countryCode;
    
    // Ülke koduna göre uzunluk kontrolü
    const requiredLength = {
        '+90': 10,   // Türkiye
        '+43': 10,   // Avusturya
        '+32': 9,    // Belçika
        '+420': 9,   // Çek Cumhuriyeti
        '+45': 8,    // Danimarka
        '+372': 8,   // Estonya
        '+358': 9,   // Finlandiya
        '+31': 9,    // Hollanda
        '+34': 9,    // İspanya
        '+46': 10,    // İsveç
        '+39': 10,   // İtalya
        '+356': 8,   // Malta
        '+48': 9,    // Polonya
        '+380': 9,   // Ukrayna
        '+353': 9,   // İrlanda
        '+1': 10,    // ABD
        '+44': 10,   // İngiltere
        '+49': 10,   // Almanya
        '+33': 9,    // Fransa
    }[selectedCountryCode];

    if (!requiredLength) {
        errors.phone = 'Geçersiz ülke kodu';
    } else if (phoneDigits.length !== requiredLength) {
        errors.phone = `${selectedCountryCode} alan kodu için telefon numarası ${requiredLength} haneli olmalıdır`;
    }
}

  try {
    // İsim-soyisim benzersizlik kontrolü
    const nameQuery = query(
      collection(db, "users"),
      where("status", "==", "active"),
      where("role", "==", "customer"),
      where("name", "==", customerData.name),
      where("surname", "==", customerData.surname)
    );
    
    const nameSnapshot = await getDocs(nameQuery);
    const nameExists = nameSnapshot.docs.some(doc => doc.id !== currentCustomerId);
    
    if (nameExists) {
      errors.name = 'Bu isim ve soyisim ile kayıtlı başka bir müşteri bulunmaktadır';
    }

    // Telefon numarası benzersizlik kontrolü
    if (customerData.phone) {
      const phoneQuery = query(
        collection(db, "users"),
        where("status", "==", "active"),
        where("role", "==", "customer"),
        where("phone.number", "==", customerData.phone.number),
        where("phone.countryCode", "==", customerData.phone.countryCode)
      );
      
      const phoneSnapshot = await getDocs(phoneQuery);
      const phoneExists = phoneSnapshot.docs.some(doc => doc.id !== currentCustomerId);
      
      if (phoneExists) {
        errors.phone = 'Bu telefon numarası ile kayıtlı başka bir müşteri bulunmaktadır';
      }
    }
  } catch (error) {
    console.error("Doğrulama hatası:", error);
    errors.general = 'Doğrulama sırasında bir hata oluştu';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};

export const validateBill = (billData) => {
  const errors = {};

  // Tarih kontrolü
  if (!billData.date) {
    errors.date = 'Tarih zorunludur';
  } else if (new Date(billData.date) > new Date()) {
    errors.date = 'Gelecek tarihli adisyon girilemez';
  }

  // Fiyat kontrolü
  if (!billData.price) {
    errors.price = 'Fiyat zorunludur';
  } else if (billData.price <= 0) {
    errors.price = 'Fiyat 0\'dan büyük olmalıdır';
  } else if (!/^\d+(\.\d{1,2})?$/.test(billData.price.toString())) {
    errors.price = 'Geçerli bir fiyat giriniz';
  }

  // İndirim kontrolü
  if (billData.discount) {
    if (billData.discount < 0) {
      errors.discount = 'İndirim 0\'dan küçük olamaz';
    } else if (billData.discount > billData.price) {
      errors.discount = 'İndirim tutarı fiyattan büyük olamaz';
    }
  }

  // Ödeme türü kontrolü
  if (!['Nakit', 'Kredi Kartı', 'Havale', 'Açık Hesap', 'Açık Hesap Ödeme'].includes(billData.paymentType)) {
    errors.paymentType = 'Geçersiz ödeme türü';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};