import { db } from "../firebase";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";

class RateLimiter {
  constructor(maxRequests = 100, timeWindow = 60000) { // 1 dakikada max 100 istek
    this.requests = new Map();
    this.maxRequests = maxRequests;
    this.timeWindow = timeWindow;
  }

  async checkLimit(userId) {
    const now = Date.now();
    const userRequests = this.requests.get(userId) || [];
    
    // Zaman penceresi dışındaki istekleri temizle
    const recentRequests = userRequests.filter(time => now - time < this.timeWindow);
    
    if (recentRequests.length >= this.maxRequests) {
      throw new Error('Rate limit exceeded. Please try again later.');
    }
    
    recentRequests.push(now);
    this.requests.set(userId, recentRequests);
    return true;
  }
}

const rateLimiter = new RateLimiter();

export const withErrorHandling = async (operation) => {
  try {
    await operation();
  } catch (error) {
    console.error('Operation error:', error);
    
    // Hata tipine göre özel mesajlar
    if (error.code === 'permission-denied') {
      throw new Error('You do not have permission to perform this action.');
    }
    if (error.code === 'not-found') {
      throw new Error('The requested resource was not found.');
    }
    if (error.code === 'already-exists') {
      throw new Error('This record already exists.');
    }
    
    throw new Error('An unexpected error occurred. Please try again.');
  }
};

export const withRateLimit = async (userId, operation) => {
  await rateLimiter.checkLimit(userId);
  return withErrorHandling(operation);
};