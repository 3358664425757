import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const countryCodes = [
  { code: '+90', country: 'TR', flag: '🇹🇷', length: 10, format: '(XXX) XXX XX XX' },
  { code: '+43', country: 'AT', flag: '🇦🇹', length: 10, format: 'XXXX XXX XXX' }, // Avusturya
  { code: '+32', country: 'BE', flag: '🇧🇪', length: 9, format: 'XXX XX XX XX' }, // Belçika
  { code: '+420', country: 'CZ', flag: '🇨🇿', length: 9, format: 'XXX XXX XXX' }, // Çek Cumhuriyeti
  { code: '+45', country: 'DK', flag: '🇩🇰', length: 8, format: 'XXXX XXXX' }, // Danimarka
  { code: '+372', country: 'EE', flag: '🇪🇪', length: 8, format: 'XXXX XXXX' }, // Estonya
  { code: '+358', country: 'FI', flag: '🇫🇮', length: 9, format: 'XXX XXX XXX' }, // Finlandiya
  { code: '+31', country: 'NL', flag: '🇳🇱', length: 9, format: 'XX XXX XXXX' }, // Hollanda
  { code: '+34', country: 'ES', flag: '🇪🇸', length: 9, format: 'XXX XXX XXX' }, // İspanya
  { code: '+46', country: 'SE', flag: '🇸🇪', length: 10, format: 'XX XXX XXXX' }, // İsveç
  { code: '+39', country: 'IT', flag: '🇮🇹', length: 10, format: 'XXX XXX XXXX' }, // İtalya
  { code: '+356', country: 'MT', flag: '🇲🇹', length: 8, format: 'XXXX XXXX' }, // Malta
  { code: '+48', country: 'PL', flag: '🇵🇱', length: 9, format: 'XXX XXX XXX' }, // Polonya
  { code: '+380', country: 'UA', flag: '🇺🇦', length: 9, format: 'XX XXX XXXX' }, // Ukrayna
  { code: '+353', country: 'IE', flag: '🇮🇪', length: 9, format: 'XX XXX XXXX' }, // İrlanda
  { code: '+1', country: 'US', flag: '🇺🇸', length: 10, format: '(XXX) XXX-XXXX' },
  { code: '+44', country: 'GB', flag: '🇬🇧', length: 10, format: 'XXXX XXX XXX' },
  { code: '+49', country: 'DE', flag: '🇩🇪', length: 10, format: 'XXXX XXXXXXX' },
  { code: '+33', country: 'FR', flag: '🇫🇷', length: 9, format: 'X XX XX XX XX' },
];

const PhoneInput = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current && isOpen) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;
      const dropdownHeight = 300;

      setDropdownPosition(spaceBelow < dropdownHeight && spaceAbove > spaceBelow ? 'top' : 'bottom');
    }
  }, [isOpen]);
  
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
    // Mevcut numarayı temizle çünkü format değişecek
    onChange({ countryCode: country.code, number: '' });
  };

  const formatPhoneNumber = (number, country) => {
    // Sadece rakamları al
    const digits = number.replace(/\D/g, '');
    
    if (digits.length === 0) return '';
    
    // Türkiye format örneği: (5XX) XXX XX XX
    if (country.code === '+90') {
      return digits
        .slice(0, country.length)
        .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '($1) $2 $3 $4');
    }
    
    // Diğer ülkeler için basit formatlama
    return digits.slice(0, country.length);
  };

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    
    if (inputValue.length <= selectedCountry.length) {
      const formattedNumber = formatPhoneNumber(inputValue, selectedCountry);
      onChange({
        countryCode: selectedCountry.code,
        number: formattedNumber
      });
    }
  };

  return (
    <div className="relative flex">
      <div className="relative">
        <button
          ref={buttonRef}
          type="button"
          className="flex items-center gap-2 px-3 py-2 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 hover:bg-gray-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedCountry.flag}</span>
          <span className="text-sm">{selectedCountry.code}</span>
          <ChevronDown className="w-4 h-4" />
        </button>
        
        {isOpen && (
          <div 
            className={`absolute z-[1100] w-48 bg-white border border-gray-300 rounded-md shadow-lg max-h-[300px] overflow-y-auto ${
              dropdownPosition === 'top' 
                ? 'bottom-full mb-1' 
                : 'top-full mt-1'
            }`}
          >
            {countryCodes.map((country) => (
              <button
                key={country.code}
                className="flex items-center gap-2 w-full px-4 py-2 text-left hover:bg-gray-100"
                onClick={() => handleCountrySelect(country)}
              >
                <span>{country.flag}</span>
                <span>{country.code}</span>
                <span className="text-sm text-gray-500">{country.country}</span>
              </button>
            ))}
          </div>
        )}
      </div>
      
      {/* Telefon Numarası Input */}
      <input
        type="tel"
        value={value.number}
        onChange={handlePhoneChange}
        placeholder={selectedCountry.format}
        className="flex-1 px-3 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
    </div>
  );
};

export default PhoneInput;