import React from 'react';

const PaymentSummary = ({ bills, costs }) => {
  // GMT+3'e göre bugünün tarihini hesaplayan fonksiyon
  const getCurrentDate = () => {
    const now = new Date();
    const offset = 3;
    const utc = now.getTime() + (now.getTimezoneOffset() * 60000);
    const targetTime = new Date(utc + (3600000 * offset));
    
    const year = targetTime.getFullYear();
    const month = String(targetTime.getMonth() + 1).padStart(2, '0');
    const day = String(targetTime.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };

  const today = getCurrentDate();
  
  // Bugünün gelirlerini hesapla
  const todaysIncome = bills
    .filter(bill => bill.date === today)
    .reduce((acc, bill) => {
      const paymentType = bill.paymentType || 'Diğer';
      const totalPrice = Number(bill.totalPrice || bill.price) || 0;
      acc[paymentType] = (acc[paymentType] || 0) + totalPrice;
      return acc;
    }, {});

  // Bugünün giderlerini hesapla
  const todaysCosts = costs
    .filter(cost => cost.date === today)
    .reduce((acc, cost) => {
      const paymentType = cost.paymentType || 'Diğer';
      const totalPrice = Number(cost.price) || 0;
      acc[paymentType] = (acc[paymentType] || 0) + totalPrice;
      return acc;
    }, {});

  // Gelir kartları için renkler
  const incomeStyles = {
    'Nakit': 'bg-green-100 text-green-800 border-green-200',
    'Kredi Kartı': 'bg-blue-100 text-blue-800 border-blue-200',
    'Havale': 'bg-purple-100 text-purple-800 border-purple-200',
  };

  // Gider kartları için renkler
  const expenseStyles = {
    'Nakit': 'bg-yellow-100 text-yellow-800 border-yellow-200',
    'Kredi Kartı': 'bg-orange-100 text-orange-800 border-orange-200',
    'Havale': 'bg-rose-100 text-rose-800 border-rose-200',
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 mb-6">
      {/* Gelirler */}
      <div className="flex-1">
        <h2 className="text-lg font-semibold text-gray-700 text-center mb-4">
          Gelir
        </h2>
        <div className="grid grid-cols-3 gap-4">
          <div className={`p-4 rounded-lg border shadow-sm ${incomeStyles['Nakit']}`}>
            <div className="text-sm font-medium">Nakit</div>
            <div className="text-lg font-bold mt-1">
              {(todaysIncome['Nakit'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
          <div className={`p-4 rounded-lg border shadow-sm ${incomeStyles['Kredi Kartı']}`}>
            <div className="text-sm font-medium">Kredi Kartı</div>
            <div className="text-lg font-bold mt-1">
              {(todaysIncome['Kredi Kartı'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
          <div className={`p-4 rounded-lg border shadow-sm ${incomeStyles['Havale']}`}>
            <div className="text-sm font-medium">Havale</div>
            <div className="text-lg font-bold mt-1">
              {(todaysIncome['Havale'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
        </div>
      </div>

      {/* Ayırıcı çizgi - mobilde yatay, masaüstünde dikey */}
      <div className="md:w-px md:h-auto md:bg-gray-200"></div>

      {/* Giderler */}
      <div className="flex-1">
        <h2 className="text-lg font-semibold text-gray-700 text-center mb-4">
          Gider
        </h2>
        <div className="grid grid-cols-3 gap-4">
          <div className={`p-4 rounded-lg border shadow-sm ${expenseStyles['Nakit']}`}>
            <div className="text-sm font-medium">Nakit</div>
            <div className="text-lg font-bold mt-1">
              {(todaysCosts['Nakit'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
          <div className={`p-4 rounded-lg border shadow-sm ${expenseStyles['Kredi Kartı']}`}>
            <div className="text-sm font-medium">Kredi Kartı</div>
            <div className="text-lg font-bold mt-1">
              {(todaysCosts['Kredi Kartı'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
          <div className={`p-4 rounded-lg border shadow-sm ${expenseStyles['Havale']}`}>
            <div className="text-sm font-medium">Havale</div>
            <div className="text-lg font-bold mt-1">
              {(todaysCosts['Havale'] || 0).toLocaleString('tr-TR')} TL
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;