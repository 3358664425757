// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { 
  onAuthStateChanged, 
  signOut, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updatePassword
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { validatePassword } from "../utils/passwordUtils";
import { SessionSecurity } from "../utils/sessionSecurity";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    let mounted = true;

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      
      try {
        if (currentUser && mounted) {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            setUser({
              ...currentUser,
              role: userDoc.data().role,
              name: userDoc.data().name,
              surname: userDoc.data().surname,
            });
          } else {
            setUser(currentUser);
          }
          SessionSecurity.initSession();
        } else if (mounted) {
          setUser(null);
        }
      } catch (error) {
        console.error("Kullanıcı bilgileri alınırken hata:", error);
        if (mounted) {
          setUser(currentUser);
        }
      } finally {
        if (mounted) {
          setLoading(false);
          setAuthInitialized(true);
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUser({
          ...userCredential.user,
          role: userData.role,
          name: userData.name,
          surname: userData.surname,
        });
      }
      
      SessionSecurity.initSession();
      return userCredential.user;
    } finally {
      setLoading(false);
    }
  };


  const register = async (email, password) => {
    const { isValid, errors } = validatePassword(password);
    if (!isValid) {
      throw new Error(errors.join('\n'));
    }

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  };

  const logout = async () => {
    await SessionSecurity.terminateSession();
    await signOut(auth);
  };

  const updateUserPassword = async (newPassword) => {
    if (!user) {
      throw new Error('No authenticated user');
    }

    const { isValid, errors } = validatePassword(newPassword);
    if (!isValid) {
      throw new Error(errors.join('\n'));
    }

    await updatePassword(user, newPassword);
  };

  const value = {
    user,
    loading,
    authInitialized,
    register,
    login,
    logout,
    updateUserPassword
  };

  return (
    <AuthContext.Provider value={value}>
      {authInitialized && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};