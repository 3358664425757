import React from 'react';
import Modal from 'react-modal';
import { Calendar, FileText, CreditCard, X } from 'lucide-react';

const CostModal = ({
  isOpen,
  onClose,
  costForm,
  setCostForm,
  onSave,
  isEditing,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        },
        content: {
          position: 'relative',
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 'auto',
          maxWidth: '600px',
          width: '90%',
          maxHeight: '90vh',
          margin: '0 auto',
          padding: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'visible'
        }
      }}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-h-[90vh] overflow-y-auto">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-red-500 to-pink-500 p-4 rounded-t-lg flex justify-between items-center">
          <h2 className="text-xl font-semibold text-white">
            {isEditing ? 'Gider Düzenle' : 'Yeni Gider'}
          </h2>
          <button
            onClick={onClose}
            className="text-white hover:text-gray-200 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-6 space-y-6">
          {/* Tarih Seçimi */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <Calendar className="h-4 w-4 text-red-500" />
              Tarih
            </label>
            <input
              type="date"
              value={costForm.date}
              onChange={(e) => setCostForm(prev => ({ ...prev, date: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
            />
          </div>

          {/* Açıklama */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-red-500" />
              Açıklama
            </label>
            <input
              type="text"
              value={costForm.description}
              onChange={(e) => setCostForm(prev => ({ ...prev, description: e.target.value }))}
              placeholder="Gider açıklaması..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
            />
          </div>

          {/* Tutar */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <FileText className="h-4 w-4 text-red-500" />
              Tutar
            </label>
            <div className="relative">
              <input
                type="number"
                value={costForm.price}
                onChange={(e) => setCostForm(prev => ({ ...prev, price: e.target.value }))}
                placeholder="0.00"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">
                TL
              </span>
            </div>
          </div>

          {/* Ödeme Türü */}
          <div className="space-y-2">
            <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <CreditCard className="h-4 w-4 text-red-500" />
              Ödeme Türü
            </label>
            <select
              value={costForm.paymentType}
              onChange={(e) => setCostForm(prev => ({ ...prev, paymentType: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent transition-all"
            >
              <option value="Nakit">Nakit</option>
              <option value="Kredi Kartı">Kredi Kartı</option>
              <option value="Havale">Havale</option>
            </select>
          </div>

          {/* Toplam Tutar */}
          <div className="bg-gradient-to-r from-red-50 to-pink-50 rounded-lg p-4">
            <div className="text-lg font-medium flex items-center justify-between">
              <span>Toplam Tutar:</span>
              <span>{costForm.price || 0} TL</span>
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="bg-gray-50 px-6 py-4 rounded-b-lg flex justify-end gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
          >
            İptal
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 rounded-md transition-all"
          >
            {isEditing ? 'Güncelle' : 'Kaydet'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CostModal;