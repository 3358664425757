import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { Search, UserPlus, Edit2, Trash2, User, Mail, Phone, X, Save, ChevronLeft, ChevronRight } from 'lucide-react';
import PhoneInput from '../components/PhoneInput';
import { validateCustomer } from '../utils/validationUtils';
import CustomDeleteDialog from '../components/CustomDeleteDialog';

Modal.setAppElement("#root");

function Customers() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // Müşteri verilerini çekme
const fetchCustomers = async () => {
  try {
    const q = query(
      collection(db, "users"),
      where("role", "==", "customer"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const customerData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const phoneData = typeof data.phone === 'string' 
        ? { countryCode: '+90', number: data.phone }
        : data.phone || { countryCode: '+90', number: '' };
      
      return {
        id: doc.id,
        ...data,
        phone: phoneData
      };
    }).sort((a, b) => a.name.localeCompare(b.name, 'tr')); // Verileri çekerken sıralama
    setCustomers(customerData);
    setFilteredCustomers(customerData);
    setTotalPages(Math.ceil(customerData.length / itemsPerPage));
  } catch (error) {
    console.error("Müşteri verileri çekilirken hata:", error);
  }
};

  useEffect(() => {
    fetchCustomers();
  }, []);

  // Update total pages when itemsPerPage changes
  useEffect(() => {
    setTotalPages(Math.ceil(filteredCustomers.length / itemsPerPage));
    setCurrentPage(1); // Reset to first page when changing items per page
  }, [filteredCustomers.length, itemsPerPage]);

  // Arama fonksiyonu
const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setSearchTerm(e.target.value);
  
  if (!searchValue) {
    setFilteredCustomers(customers);
    return;
  }

  const searchTerms = searchValue.trim().split(/\s+/);
  
  const filtered = customers.filter((customer) => {
    const fullName = `${customer.name?.toLowerCase() || ''} ${customer.surname?.toLowerCase() || ''}`;
    const reversedFullName = `${customer.surname?.toLowerCase() || ''} ${customer.name?.toLowerCase() || ''}`;
    const fullPhone = `${customer.phone?.countryCode || ''}${customer.phone?.number || ''}`.replace(/\s+/g, '');
    const phoneWithoutCode = `${customer.phone?.number || ''}`.replace(/\s+/g, '');
    
    // Tam metin araması
    if (fullName.includes(searchValue) || 
        reversedFullName.includes(searchValue) || 
        fullPhone.includes(searchValue) || 
        phoneWithoutCode.includes(searchValue)) {
      return true;
    }

    // Birden fazla kelime ile arama yapılıyorsa
    if (searchTerms.length > 1) {
      return searchTerms.every(term => 
        fullName.includes(term) || 
        reversedFullName.includes(term) || 
        fullPhone.includes(term) || 
        phoneWithoutCode.includes(term)
      );
    }
    
    // Tek kelime ile arama
    const term = searchValue.trim();
    return (
      (customer.name?.toLowerCase() || '').includes(term) ||
      (customer.surname?.toLowerCase() || '').includes(term) ||
      (customer.emailAddress?.toLowerCase() || '').includes(term) ||
      fullPhone.includes(term) ||
      phoneWithoutCode.includes(term)
    );
  }).sort((a, b) => a.name.localeCompare(b.name, 'tr'));

  setFilteredCustomers(filtered);
  setCurrentPage(1);
};

// İlk harfi büyük, diğerlerini küçük yapan yardımcı fonksiyon
const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  // Birden fazla kelimeli isimleri de kapsayacak şekilde düzenleme
  return str.trim().toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};

  // Get current page items
  const getCurrentPageItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);
  };

  // Pagination controls
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Modal açma/kapatma
const openModal = (customer = null) => {
  setModalIsOpen(true);
  if (customer) {
    setIsEditing(true);
    setSelectedCustomerId(customer.id);
    // Eğer eski kayıtlarda phone objesi yoksa, yeni yapıya uygun hale getir
    const phoneData = customer.phone || {
      countryCode: '+90',
      number: customer.phone || ''
    };
    setCustomerForm({
      ...customer,
      phone: phoneData
    });
  } else {
    setIsEditing(false);
    setSelectedCustomerId(null);
    setCustomerForm({
      name: "",
      surname: "",
      emailAddress: "",
      phone: {
        countryCode: '+90',
        number: ''
      },
      role: "customer",
      status: "active",
    });
  }
};

  const closeModal = () => setModalIsOpen(false);

const [customerForm, setCustomerForm] = useState({
  name: "",
  surname: "",
  emailAddress: "",
  phone: {
    countryCode: '+90',
    number: ''
  },
  role: "customer",
  status: "active",
});

  // Müşteri ekleme/düzenleme
const handleSaveCustomer = async () => {
  try {
    // Form verilerini düzenle
    const formattedCustomerData = {
      ...customerForm,
      name: capitalizeFirstLetter(customerForm.name),
      surname: capitalizeFirstLetter(customerForm.surname),
    };

    const validation = await validateCustomer(formattedCustomerData, db, selectedCustomerId);
    
    if (!validation.isValid) {
      const errorMessage = Object.values(validation.errors).join('\n');
      alert(errorMessage);
      return;
    }

    if (isEditing) {
      const customerRef = doc(db, "users", selectedCustomerId);
      await updateDoc(customerRef, formattedCustomerData);
      alert("Müşteri başarıyla güncellendi!");
    } else {
      await addDoc(collection(db, "users"), formattedCustomerData);
      alert("Yeni müşteri başarıyla eklendi!");
    }

    fetchCustomers();
    closeModal();
  } catch (error) {
    console.error("Müşteri kaydedilirken hata:", error);
    alert("Bir hata oluştu: " + error.message);
  }
};

  // Müşteri silme (soft delete)
  const handleDeleteCustomer = async (customerId) => {
    try {
      const customerRef = doc(db, "users", customerId);
      await updateDoc(customerRef, { status: "deleted" });
      fetchCustomers();
    } catch (error) {
      console.error("Müşteri silinirken hata:", error);
    }
  };

// Pagination Controls için yardımcı fonksiyon
const getPageNumbers = (currentPage, totalPages) => {
  const delta = 2; // Aktif sayfanın her iki yanında kaç sayfa gösterileceği
  const range = [];
  const rangeWithDots = [];
  let l;

  // İlk sayfa her zaman gösterilir
  range.push(1);

  if (totalPages <= 1) {
    return range;
  }

  // Toplam sayfa sayısı küçükse tüm sayfaları göster
  if (totalPages <= 7) {
    for (let i = 2; i < totalPages; i++) {
      range.push(i);
    }
  } else {
    // Aktif sayfanın etrafındaki sayfaları hesapla
    for (let i = Math.max(2, currentPage - delta); i <= Math.min(totalPages - 1, currentPage + delta); i++) {
      range.push(i);
    }
  }

  // Son sayfa her zaman gösterilir
  if (totalPages > 1) {
    range.push(totalPages);
  }

  // Noktalı aralıkları ekle
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};

// Pagination Controls komponenti
const PaginationControls = () => {
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  return (
    <div className="mt-4 flex justify-between items-center">
      <div className="text-sm text-gray-700">
        Toplam {filteredCustomers.length} kayıttan {(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, filteredCustomers.length)} arası gösteriliyor
      </div>
      <div className="flex items-center gap-1">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-2 py-1 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          <ChevronLeft className="h-5 w-5" />
        </button>

        {pageNumbers.map((pageNumber, index) => (
          pageNumber === '...' ? (
            <span key={`dots-${index}`} className="px-2 py-1">
              {pageNumber}
            </span>
          ) : (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`px-3 py-1 border rounded-md ${
                currentPage === pageNumber 
                  ? 'bg-blue-500 text-white' 
                  : 'hover:bg-gray-50'
              }`}
            >
              {pageNumber}
            </button>
          )
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-2 py-1 border rounded-md disabled:opacity-50 disabled:cursor-not-allowed hover:bg-gray-50"
        >
          <ChevronRight className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

  return (
    <div className="p-6">
      {/* Header */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 gap-4">
        {/* Arama Alanı */}
        <div className="relative w-full md:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Müşteri Ara"
            value={searchTerm}
            onChange={handleSearch}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {/* Sayfa başına öğe seçici */}
        <div className="flex items-center gap-4">
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value={10}>10 Kayıt</option>
            <option value={20}>20 Kayıt</option>
            <option value={50}>50 Kayıt</option>
            <option value={100}>100 Kayıt</option>
          </select>

          <button
            onClick={() => openModal()}
            className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-cyan-500 to-blue-500 text-white rounded-md hover:from-cyan-600 hover:to-blue-600 transition-all duration-300"
          >
            <UserPlus className="h-5 w-5" />
            Yeni Müşteri
          </button>
        </div>
      </div>

      {/* Müşteri Tablosu */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Soyad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefon</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">İşlemler</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {getCurrentPageItems().sort((a, b) => a.name.localeCompare(b.name, 'tr')).map((customer) => (
                <tr key={customer.id} className="hover:bg-gray-50 transition-colors">
                  <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.surname}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{customer.emailAddress}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {customer.phone?.countryCode} {customer.phone?.number}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right space-x-2">
                    <button
                      onClick={() => openModal(customer)}
                      className="inline-flex items-center px-3 py-1 text-sm text-blue-600 hover:text-blue-700 hover:bg-blue-50 rounded-md transition-colors"
                    >
                      <Edit2 className="h-4 w-4 mr-1" />
                      Düzenle
                    </button>
                    <CustomDeleteDialog 
                      onConfirm={() => handleDeleteCustomer(customer.id)}
                      entityType="musteri"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
	<PaginationControls />

{/* Müşteri Modal */}
      {modalIsOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div 
            className="fixed inset-0 bg-black/30"
            onClick={closeModal}
          />
          
          <div className="relative bg-white rounded-lg shadow-xl w-full max-w-[500px] mx-4">
            {/* Modal Header */}
            <div className="bg-gradient-to-r from-cyan-500 to-blue-500 p-4 flex justify-between items-center rounded-t-lg">
              <h2 className="text-xl font-semibold text-white">
                {isEditing ? "Müşteri Düzenle" : "Yeni Müşteri"}
              </h2>
              <button
                onClick={closeModal}
                className="text-white hover:text-gray-200 transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {/* Modal Body */}
            <div className="p-6 space-y-4">
              {/* Ad */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <User className="h-4 w-4 text-blue-500" />
                  Ad
                </label>
                <input
                  type="text"
                  placeholder="Ad"
                  value={customerForm.name}
                  onChange={(e) => setCustomerForm({ ...customerForm, name: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Soyad */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <User className="h-4 w-4 text-blue-500" />
                  Soyad
                </label>
                <input
                  type="text"
                  placeholder="Soyad"
                  value={customerForm.surname}
                  onChange={(e) => setCustomerForm({ ...customerForm, surname: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Email */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <Mail className="h-4 w-4 text-blue-500" />
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  value={customerForm.emailAddress}
                  onChange={(e) => setCustomerForm({ ...customerForm, emailAddress: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>

              {/* Telefon */}
              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <Phone className="h-4 w-4 text-blue-500" />
                  Telefon
                </label>
                <PhoneInput
                  value={customerForm.phone}
                  onChange={(phoneData) => 
                    setCustomerForm(prev => ({
                      ...prev,
                      phone: phoneData
                    }))
                  }
                />
              </div>
            </div>

            {/* Modal Footer */}
            <div className="bg-gray-50 px-6 py-4 flex justify-end gap-3 rounded-b-lg">
              <button
                onClick={closeModal}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors"
              >
                İptal
              </button>
              <button
                onClick={handleSaveCustomer}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:from-cyan-600 hover:to-blue-600 rounded-md transition-all"
              >
                <Save className="h-4 w-4" />
                {isEditing ? "Güncelle" : "Kaydet"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Customers;