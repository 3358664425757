import React from 'react';
import { Calendar, DollarSign, FileText, CreditCard, Trash2, Edit2 } from 'lucide-react';
import CustomDeleteDialog from './CustomDeleteDialog';

const CostCard = ({ cost, onEdit, onDelete }) => {
  const getDateColor = (dateStr) => {
    const isToday = new Date(dateStr).toDateString() === new Date().toDateString();
    return isToday 
      ? 'bg-gradient-to-r from-red-50 to-red-100' 
      : 'bg-gradient-to-r from-orange-50 to-orange-100';
  };

  return (
    <div className="w-full max-w-sm bg-white rounded-lg shadow transition-all duration-300 hover:shadow-lg">
      <div className="p-6">
        {/* Tarih Başlığı */}
        <div className={`${getDateColor(cost.date)} -mx-6 -mt-6 px-6 py-3 mb-4 rounded-t-lg flex items-center gap-2`}>
          <Calendar className="h-4 w-4 text-gray-600" />
          <span className="font-medium text-gray-700">
            {new Date(cost.date).toLocaleDateString("tr-TR")}
          </span>
        </div>

        {/* Gider Detayları */}
        <div className="space-y-3 mb-4">
          <div className="flex items-center gap-2">
            <FileText className="h-4 w-4 text-red-500" />
            <span className="text-gray-600">
              <span className="font-medium text-gray-900">Açıklama:</span> {cost.description}
            </span>
          </div>
          
          <div className="flex items-center gap-2">
            <DollarSign className="h-4 w-4 text-red-500" />
            <span className="text-gray-600">
              <span className="font-medium text-gray-900">Tutar:</span> {cost.price} TL
            </span>
          </div>

          <div className="flex items-center gap-2">
            <CreditCard className="h-4 w-4 text-red-500" />
            <span className="text-gray-600">
              <span className="font-medium text-gray-900">Ödeme Türü:</span> {cost.paymentType}
            </span>
          </div>
        </div>

        {/* Aksiyon Butonları */}
        <div className="flex gap-2 justify-end">
          <button
            onClick={() => onEdit(cost)}
            className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-red-600 hover:text-red-700 hover:bg-red-50 rounded-md transition-colors duration-300"
          >
            <Edit2 className="h-4 w-4" />
            <span>Düzenle</span>
          </button>
          <CustomDeleteDialog onConfirm={() => onDelete(cost.id)} />
        </div>
      </div>
    </div>
  );
};

export default CostCard;