import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCcHkggEPg2vv9q56GRjswtpIppSlYeeaY",
  authDomain: "adisyonapp-8a4cb.firebaseapp.com",
  projectId: "adisyonapp-8a4cb",
  storageBucket: "adisyonapp-8a4cb.firebasestorage.app",
  messagingSenderId: "197344025265",
  appId: "1:197344025265:web:cd2f379713ba07e529e090",
  measurementId: "G-6BKYYV4796"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
